import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ViewAll = () => {
  const navigate = useNavigate();

  // Assuming you already have these states
  const [companyFilter, setCompanyFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [lengthFilter, setLengthFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");

  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const trucks = JSON.parse(sessionStorage.getItem("filteredTrucks")) || [];

  useEffect(() => {
    let filtered = trucks;

    // Apply filters
    if (companyFilter) {
      filtered = filtered.filter((truck) => truck.company === companyFilter);
    }
    if (typeFilter) {
      filtered = filtered.filter((truck) => truck.vehicle_name === typeFilter);
    }
    if (lengthFilter) {
      filtered = filtered.filter((truck) => truck.in_length === lengthFilter);
    }
    if (priceFilter) {
      filtered = filtered.filter((truck) => truck.price === priceFilter);
    }

    setFilteredTrucks(filtered);
  }, [companyFilter, typeFilter, lengthFilter, priceFilter, trucks]);

  return (
    <div className="d-flex p-5 row">
      <div className="view-all-page">
        <h1>Available Trucks</h1>
        <div className="filter-bar">
          {/* Company Filter */}
          <select
            onChange={(e) => setCompanyFilter(e.target.value)}
            value={companyFilter}
          >
            <option value="">Company</option>
            {Array.from(new Set(trucks.map((truck) => truck.company))).map(
              (company, index) => (
                <option key={index} value={company}>
                  {company}
                </option>
              )
            )}
          </select>

          {/* Type Filter */}
          <select
            onChange={(e) => setTypeFilter(e.target.value)}
            value={typeFilter}
          >
            <option value="">Truck Type</option>
            {Array.from(new Set(trucks.map((truck) => truck.vehicle_name))).map(
              (vehicle_name, index) => (
                <option key={index} value={vehicle_name}>
                  {vehicle_name}
                </option>
              )
            )}
          </select>

          {/* Length Filter */}
          <select
            onChange={(e) => setLengthFilter(e.target.value)}
            value={lengthFilter}
          >
            <option value="">Truck Length</option>
            {Array.from(new Set(trucks.map((truck) => truck.length))).map(
              (length, index) => (
                <option key={index} value={length}>
                  {length}
                </option>
              )
            )}
          </select>

          {/* Price Filter */}
          <select
            onChange={(e) => setPriceFilter(e.target.value)}
            value={priceFilter}
          >
            <option value="">Price</option>
            {Array.from(new Set(trucks.map((truck) => truck.price))).map(
              (price, index) => (
                <option key={index} value={price}>
                  {price}
                </option>
              )
            )}
          </select>
        </div>

        {filteredTrucks.length > 0 ? (
          <div className="truck-list">
            {filteredTrucks.map((truck, index) => (
              <div className="truck-card" key={index}>
                <h2
                  style={{
                    fontSize: "30px",
                    justifyContent: "center",
                    display: "flex",
                    color: "#F66711",
                  }}
                >
                  {truck.vehicle_name}
                </h2>
                <img
                  src={truck.href}
                  alt={truck.vehicle_name}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "300px",
                    marginBottom: "1rem",
                    borderRadius: "8px",
                  }}
                />
                <p>Company: {truck.company}</p>
                <p>Price: {truck.price}</p>
                <p>{truck.info}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>No trucks available with the selected filters.</p>
        )}
      </div>
      {/* <div>
        <button className="btn btn-base btn-back" onClick={() => navigate("/")}>
          Back
        </button>
      </div> */}
    </div>
  );
};

export default ViewAll;
